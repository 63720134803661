
import Datatable from "@/components/datatable/Datatable.vue";
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ContentLoader } from "vue-content-loader";
import { usuarioList } from "@/services/UsuarioService";
import useEmitter from '@/composables/Emmiter';
import ApiService from '@/services/ApiService';
import DeletarUsuarioModal from "./components/DeletarUsuarioModal.vue";
import VisualizarUsuarioModal from "./components/VisualizarUsuarioModal.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "concessionariaadd",

  components: {
    Datatable,
    ContentLoader,
    DeletarUsuarioModal,
    VisualizarUsuarioModal
  },

  setup() {
    const data: any = reactive({
       info: {
        current_page: "",
        data: [],
        first_page_url: "",
        from: "",
        last_page: "",
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: "",
        to: "",
        total: "",
      },
       nome: "",
      responsavel: "",  

    });
 
    let page = ref(1);
    const concessionaria: any = ref([]);
    const router = useRouter();
    const contagempagina = 20;
    const contagemArray = 30;
    let animate = ref(true);
    const emitter = useEmitter();
    const dadosUsuario: any = ref([]);
    const fastFilter: any = ref("");
    const lastFastFilter: any = ref("");
    const codconcessionaria: any = ref({});
    const codMarcaselect: any = ref({});
    const filtroConsultor: any = ref({});
    const filtroCodatria: any = ref({});
    const dadosUsuarioespecifico = ref({});
    const usuario: any = ref({});
    let postData: any = ref({}); 

     onMounted(async () => {
      listaUsuario();
      procuraAcesso(postData.value);
     }); 

    emitter.on("filtrar-crud-usuarios", async (filterPostData) => {
      postData.value = {
        nome: filterPostData.nome,
        codNivel: filterPostData.codNivel 
      };

      fastFilter.value = "";

      await listaUsuario();
    }); 


    function chamarespecifico(usuario) {
       dadosUsuarioespecifico.value = usuario;
      } 

    function goToParams(routeName, usuarioRef) {
      router.push({
        name: routeName,
        params: {
          codUsuario: usuarioRef.codUsuario,
        },
      });
    } 

    const delayTimer = ref();

    const store = useStore();
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    function filterData() {
      clearTimeout(delayTimer.value);
      delayTimer.value = setTimeout(function () {
        if(lastFastFilter.value != fastFilter.value){
          postData.value.nome = fastFilter.value;
          listaUsuario();
        }

        lastFastFilter.value = fastFilter.value;
      }, 700);
    } 

     async function listaUsuario(page = 1) {
      animate.value = true;
      let response = await usuarioList(postData.value, page);
      data.value = response;
      animate.value = false;
    } 

    function procuraAcesso(response){
      if  ((response) == -1){
        return "Administrador";
      };
       if ((response) == 1){
        return distribuidor == "stellantis" ? "Gestor de checklist" : "Gerência de Pós-venda";
      };
      if ((response) == 2){
        return "Consultor";
      };
      if ((response) == 3){
        return "Orçamentista";
      };
      if ((response) == 4){
        return "Controlador de quadro";
      };
      if ((response) == 5){
        return "Visualizador de quadro";
      };
      
    };
    
    function goTo(url) {
      router.push(url);
    }

    return {
      concessionaria,
      goTo,
      data, 
      animate,
      page,
      contagempagina,
      contagemArray,
      dadosUsuario,
      chamarespecifico,
      dadosUsuarioespecifico,
      goToParams,
      codconcessionaria,
      codMarcaselect,
      filtroConsultor,
      filtroCodatria,
      fastFilter,
      filterData,
      postData,
      procuraAcesso,
      usuario,
      listaUsuario
    };
  },
});
